import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import { Container } from '../components/Grid';
import Typography from '../components/Typography';
import Band from '../components/Band/Band';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO';

export default ({ data }) => (
    <Layout>
        <SEO
            title="Thank You"
            description=""
            meta={[
                {
                    name: 'robots',
                    content: 'noindex',
                },
            ]}
        />
        <Hero mobileImage={data.mobileImage} desktopImage={data.desktopImage} logoColor="white" />
        <Band size="md">
            <Container>
                <Typography>
                    <PageHeader as="h1">Thank You</PageHeader>
                    <p>Thank you for contacting us. Your message has been sent successfully.</p>
                </Typography>
            </Container>
        </Band>
    </Layout>
);

export const query = graphql`
    query ThankYouPageQuery {
        desktopImage: file(relativePath: { eq: "unsplash-N2u-VbYMnmU.jpg" }) {
            ...HeroBackgroundImage_Desktop
        }
        mobileImage: file(relativePath: { eq: "unsplash-sm-N2u-VbYMnmU.jpg" }) {
            ...HeroBackgroundImage_Mobile
        }
    }
`;
